import validate from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.54.0_iored_q4wnf4kdw6i3bzt3cxgcqblula/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45mail_45visit_45link_45global from "/app/apps/vrabotuvanjemk/middleware/01.mail-visit-link.global.ts";
import _01_45layout_45global from "/app/apps/site/middleware/01.layout.global.ts";
import _02_45gtag_45reset_45global from "/app/apps/site/middleware/02.gtag-reset.global.ts";
import _03_45info_45pages_45global from "/app/apps/site/middleware/03.info-pages.global.ts";
import _04_45vrabo_45articles_45global from "/app/apps/site/middleware/04.vrabo-articles.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.54.0_iored_q4wnf4kdw6i3bzt3cxgcqblula/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45mail_45visit_45link_45global,
  _01_45layout_45global,
  _02_45gtag_45reset_45global,
  _03_45info_45pages_45global,
  _04_45vrabo_45articles_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "track-page-visit-and-seen": () => import("/app/apps/site/layers/Job/middleware/track-page-visit-and-seen.ts"),
  auth: () => import("/app/apps/site/middleware/auth.ts"),
  search: () => import("/app/apps/site/middleware/search.ts")
}